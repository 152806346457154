import React, { useState } from "react"
import { Badge, Col, Row } from "reactstrap"
import "react-perfect-scrollbar/dist/css/styles.css"
import { getRequestStatusColor, mapQCStatus } from "helpers/misc_helpers"
import { useLoading } from "helpers/custom_hooks"
import { ArchiveRequest } from "helpers/backend_helper"
import moment from "moment"
import CompanyInfoModal from "../../components/Common/CompanyInfoModal"
import UserInfoModal from "components/Common/UserInfoModal"
import PartInfoModal from "./PartInfoModal"
import RatingModal from "./RatingModal"

const ChatHeader = ({ chat, company, user }) => {
  const [archiveModal, setArchiveModal] = useState(false)
  const [companyInfoModal, setCompanyInfoModal] = useState(false)
  const [partInfoModal, setPartInfoModal] = useState(false)
  const [ratingModal, setRatingModal] = useState(false)

  const setLoading = useLoading()

  const handleArchive = async () => {
    setLoading(true)
    setRatingModal(true)
    await ArchiveRequest({ requestID: chat.id })
    setArchiveModal(false)
    setLoading(false)
  }

  return (
    <div className="p-4 pb-3 border-bottom ">
      <Row>
        <Col>
          <Row>
            <Col className="d-flex align-items-center">
              <h4 className="mb-0 me-2">{chat.partNumber}</h4>
              <i
                className="bx bx-info-circle font-size-18 d-block"
                style={{ cursor: "pointer" }}
                onClick={_ => setPartInfoModal(true)}
              />
              <Badge
                color="primary"
                className={
                  "me-1 ms-3 p-2 " + getRequestStatusColor(chat.status)
                }
              >
                {chat.status}
              </Badge>
            </Col>
          </Row>
          <Row>
            <p className="mb-0 mt-2">
              {chat?.quantity} pieces
              {chat?.price &&
                " x " + chat?.price?.toFixed(5) + " " + chat?.currency}
            </p>
          </Row>
          <Row className="mt-1">
            <p className="mb-0">
              <b>Date of delivery:</b>{" "}
              {chat?.deliveryDate
                ? moment(chat?.deliveryDate).format("DD.MM.YYYY")
                : "-"}
            </p>
          </Row>
          {chat?.qcStatus && (
            <Row>
              <Col>
                <p className="mt-2 d-flex align-items-center">
                  <b>QC: </b>
                  {(() => {
                    const qcStatus = mapQCStatus(chat.qcStatus)
                    return (
                      <Badge
                        color={qcStatus?.color}
                        className={"ms-2 p-2 badge-soft-" + qcStatus?.color}
                      >
                        {qcStatus?.label}
                      </Badge>
                    )
                  })()}
                </p>
              </Col>
            </Row>
          )}
        </Col>
        <Col>
          {company?.logoURL ? (
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={company?.logoURL ?? defaultAvatar}
                alt=""
                style={{
                  maxWidth: "200px",
                  maxHeight: "90px",
                  objectFit: "cover",
                }}
              />
              <i
                className="bx bx-info-circle ms-2 font-size-18"
                style={{ cursor: "pointer" }}
                onClick={_ => setCompanyInfoModal(true)}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="text-center mb-0">{company?.name}</h5>
              <i
                className="bx bx-info-circle ms-2 font-size-18"
                style={{ cursor: "pointer" }}
                onClick={_ => setCompanyInfoModal(true)}
              />
            </div>
          )}
        </Col>
        <Col className="d-flex align-items-start justify-content-end flex-wrap">
          {!chat.isArchived && (
            <button
              className="btn btn-soft-dark mb-1"
              onClick={_ => setArchiveModal(true)}
            >
              Archive Request
            </button>
          )}
          {chat.status == "accepted" && (
            <>
              {chat.qcRequestID && chat.qcStatus != -1 ? (
                <>
                  <button
                    className="btn btn-dark ms-2 mb-1"
                    onClick={_ =>
                      (window.location.href =
                        "/qc-requests/" + chat.qcRequestID)
                    }
                  >
                    View QC-Request
                  </button>
                </>
              ) : (
                <>
                  {chat.buyer?.id == user?.company?.id && (
                    <button
                      className="btn btn-primary ms-2 mb-1"
                      onClick={_ =>
                        (window.location.href =
                          "/qc-request-wizard?requestID=" + chat.id)
                      }
                    >
                      Create QC-Request
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      <UserInfoModal
        show={archiveModal}
        onCancel={_ => setArchiveModal(false)}
        onConfirm={handleArchive}
        message="Are you certain you wish to archive this request? Please note that the request will be archived for both parties involved."
      />

      {companyInfoModal && (
        <CompanyInfoModal
          part={{ company }}
          modal={true}
          close={_ => setCompanyInfoModal(false)}
          locationID={
            chat?.buyer?.id == company?.id && chat?.locationID
              ? chat.locationID
              : undefined
          }
        />
      )}
      <PartInfoModal
        request={chat}
        modal={partInfoModal}
        close={_ => setPartInfoModal(false)}
      />
      <RatingModal
        show={ratingModal}
        onCancel={_ => window.location.reload()}
        request={chat}
      />
    </div>
  )
}

export default ChatHeader
